import { useTranslation } from 'react-i18next';
import { ErrorMapCtx, z, ZodIssueOptionalMessage } from 'zod';

const useZodErrorMap = () => {
    const { t } = useTranslation();
    const customErrorMap = (issue: ZodIssueOptionalMessage, ctx: ErrorMapCtx) => {
        if (issue.code === 'invalid_type') {
            if (issue.expected === 'string') {
                return { message: t('zod_invalid_type_expected_string') };
            } else if (issue.expected === 'number') {
                return { message: t('zod_invalid_type_expected_number') };
            } else if (issue.expected === 'date') {
                return { message: t('zod_invalid_type_expected_date') };
            } else if (issue.expected === 'array') {
                return { message: t('zod_invalid_array') };
            } else if (issue.expected === 'undefined') {
                return { message: t('zod_field_required', { field: issue.path[0] }) };
            }
        } else if (issue.code === 'too_small') {
            if (issue.type === 'number') {
                return { message: t('zod_number_too_small', { minimum: issue.minimum }) };
            } else if (issue.type === 'string') {
                return { message: t('zod_string_too_short', { minimum: issue.minimum }) };
            } else if (issue.type === 'array') {
                return { message: t('zod_array_too_short', { minimum: issue.minimum }) };
            }
        } else if (issue.code === 'too_big') {
            if (issue.type === 'number') {
                return { message: t('zod_number_too_big', { maximum: issue.maximum }) };
            } else if (issue.type === 'string') {
                return { message: t('zod_string_too_long', { maximum: issue.maximum }) };
            } else if (issue.type === 'array') {
                return { message: t('zod_array_too_long', { maximum: issue.maximum }) };
            }
        } else if (issue.code === 'invalid_string') {
            if (issue.validation === 'email') {
                return { message: t('zod_invalid_email') };
            }
        }
        return { message: ctx.defaultError };
    };

    z.setErrorMap(customErrorMap);
};
export default useZodErrorMap;
