import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LoadingButton } from '@mui/lab';

import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import DialogContent from '@mui/material/DialogContent';

import { Done, Warning } from '@mui/icons-material';

import { PailheTextField } from '@components/generic/PailheComponents';

import { usePromise } from '@hooks/usePromise';

import { getCurrentUser, getUserType } from '@libs/getSharedVar';
import { removeAccessToken } from '@libs/jwt';

import { deleteEntity } from '@api/common/deleteEntity';

const DeleteAccountDialog = ({
    hasPaymentRequestsOrRevenues,
    handleToggleAccountDeletionDialog,
}: IDeleteAccountProps) => {
    const { t, i18n } = useTranslation();
    const locale = i18n.language;
    const { permissions, email } = getCurrentUser();
    const [emailVerification, setEmailVerification] = useState('');
    const isCorrectEmail = email === emailVerification;
    const isButtonDisabled = !isCorrectEmail || permissions !== 'owner';
    const side = getUserType();

    const [deleteAccountQuery, deleteAccountAction] = usePromise(deleteEntity);

    const isAccountSuccessfullyDeleted = deleteAccountQuery.status === 'success';
    const isAccountDeletionError = deleteAccountQuery.status === 'failure';
    const isAccountDeletionLoading = deleteAccountQuery.status === 'loading';

    const acceptDeletionAndRedirect = () => {
        removeAccessToken(side);
        window.location.assign(`https://affilae.com/${locale}/logiciel-affiliation/`);
    };

    return (
        <Dialog
            open={Boolean(open)}
            onClose={() => {
                isAccountSuccessfullyDeleted
                    ? acceptDeletionAndRedirect()
                    : handleToggleAccountDeletionDialog();
            }}
            PaperProps={{
                'data-testid': 'deleteDialog',
            }}
        >
            {!isAccountSuccessfullyDeleted ? (
                <>
                    <DialogTitle
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            position: 'relative',
                            padding: '1.5rem 0.5rem 1.5rem 1.5rem',
                            fontWeight: 'bold',
                            fontSize: '1.4rem',
                        }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center', gap: '1.5rem' }}>
                            <Warning
                                sx={{
                                    fontSize: '2.5rem',
                                    backgroundColor: 'rgba(231, 29, 19, 0.1)',
                                    padding: '0.5rem',
                                    borderRadius: '50%',
                                    color: 'red',
                                }}
                            />
                            {t('advertiser_form_dialog_delete_account_title')}
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        {isAccountDeletionError && (
                            <Alert severity='error'>{deleteAccountQuery.error.message}</Alert>
                        )}
                        <DialogContentText>
                            {side === 'advertiser' && (
                                <div>{t(side + '_form_dialog_delete_account_content')}</div>
                            )}

                            {side === 'publisher' && hasPaymentRequestsOrRevenues && (
                                <div>
                                    <div>{t(side + '_form_dialog_delete_account_content')}</div>

                                    <div>
                                        {t(
                                            side +
                                                '_form_dialog_delete_account_content_payments_and_earnings',
                                        )}
                                    </div>

                                    <div>
                                        {t(side + '_form_dialog_deleted_account_content_footer')}
                                    </div>
                                </div>
                            )}

                            {side === 'publisher' && !hasPaymentRequestsOrRevenues && (
                                <div>
                                    <div>{t(side + '_form_dialog_delete_account_content')}</div>

                                    <div>
                                        {t(side + '_form_dialog_deleted_account_content_footer')}
                                    </div>
                                </div>
                            )}
                        </DialogContentText>
                        <PailheTextField
                            value={emailVerification}
                            style={{ marginTop: '1rem' }}
                            placeholder='Email'
                            error={!isCorrectEmail}
                            onChange={(event) => {
                                setEmailVerification(event.target.value);
                            }}
                            data-testid='emailField'
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={isAccountDeletionLoading}
                            color='quiet'
                            onClick={() => handleToggleAccountDeletionDialog()}
                        >
                            {t('generic_cancel')}
                        </Button>
                        <div
                            className='submitWrapper'
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <LoadingButton
                                onClick={async () => await deleteAccountAction()}
                                variant='contained'
                                disabled={isButtonDisabled || isAccountDeletionLoading}
                                loading={isAccountDeletionLoading}
                            >
                                {t('generic_delete')}
                            </LoadingButton>
                        </div>
                    </DialogActions>
                </>
            ) : (
                <>
                    <DialogTitle
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            position: 'relative',
                            padding: '1.5rem 0.5rem 1.5rem 1.5rem',
                            fontWeight: 'bold',
                            fontSize: '1.4rem',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '1.5rem',
                            }}
                        >
                            <Done
                                sx={{
                                    fontSize: '2.5rem',
                                    backgroundColor: 'rgba(22, 136, 70, 0.1)',
                                    padding: '0.5rem',
                                    borderRadius: '50%',
                                    color: 'green',
                                }}
                            />
                            {t('advertiser_form_dialog_delete_account_confirmation_title')}
                        </div>
                    </DialogTitle>
                    <DialogContent
                        sx={{ display: 'flex', flexDirection: 'column', maxWidth: '500px' }}
                    >
                        <span data-testid='deletedAccountMessage'>
                            {t('user_details_form_deleted_account_with_redirection')}
                        </span>
                        <span data-testid='deletedAccountMessageOk'>
                            {t('user_details_form_dialog_deleted_account_with_redirection_ok')}
                        </span>
                    </DialogContent>
                    <DialogActions>
                        <Button variant='contained' onClick={acceptDeletionAndRedirect}>
                            {t('generic_ok')}
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};
export default DeleteAccountDialog;

interface IDeleteAccountProps {
    open: boolean;
    handleToggleAccountDeletionDialog: () => void;
    hasPaymentRequestsOrRevenues?: boolean;
}
